import cn from 'classnames';

import { IAutoAcceptInternetBidsProps } from './types';

import styles from './auto-accept-internet-bids.module.scss';

export function AutoAcceptInternetBids({ autoAcceptingBidsStatus, handleClose }: IAutoAcceptInternetBidsProps) {
  const autoAcceptingBidsStatusTextColor = cn({
    'text-green': autoAcceptingBidsStatus,
    'text-red': !autoAcceptingBidsStatus,
  });

  return (
    <div className={styles['auto-accept-intenet-bids-toast']}>
      <button aria-label='close button' className={styles['close-btn']} onClick={handleClose} />
      <strong>
        Auto-accept Internet Bids is:{' '}
        <span className={styles[`${autoAcceptingBidsStatusTextColor}`]}>{autoAcceptingBidsStatus ? `ON` : `OFF`}</span>
      </strong>
      {autoAcceptingBidsStatus && <p>Press Alt+a to return to normal functionality.</p>}
    </div>
  );
}
