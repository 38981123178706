import { captureException } from '@sentry/react';

import { HeapTrackError } from 'types';

/**
 * @function getSubStringBetweenStartAndEndToken - A utility function that returns the substring of a given string.
 * @param {string} message string to be substring
 * @param {string} startToken start token from where substring starts
 * @param {string} endToken end token to where substring ends
 * @returns {string} substring between the start and end tokens
 */
export const getSubStringBetweenStartAndEndToken = (
  message: string,
  startToken: string,
  endToken: string | undefined = undefined,
) => {
  const startIndex = message.indexOf(startToken) + startToken.length;
  const endIndex = endToken === undefined ? undefined : message.indexOf(endToken, startIndex);

  return message.substring(startIndex, endIndex);
};

/**
 * @function isValueNull - A utility function that returns true if a value is empty
 * @param {any} value value to be checked
 */
export const isValueNull = (value: any): boolean => {
  if (!value || value === 'null' || value === '0') {
    return true;
  }
  return false;
};

/**
 * A utility function that returns an Error object created using the given name and message
 * @param {string} message - Error message
 * @param {string} [name] - Error name
 * @returns {Error} Returns an instance of Error
 */
export const formatError = (message: string, name?: string): Error => {
  const error = new Error(message);
  if (name) error.name = name;
  return error;
};

/**
 * Sends heap event to track with 10 retries if the heap is not loaded in the browser.
 *
 * @param {string} heapEvent - Heap event to track.
 * @param {unknown} eventData - Any additional information to include in event.
 * @param {number} retryCount - Number of attempts the function did if heap not loaded.
 */
export const trackHeapEvent = (heapEvent: string, eventData = {}, retryCount = 0) => {
  const retryAttempt = retryCount + 1;
  if (window?.heap?.loaded) {
    try {
      window.heap.track(heapEvent, eventData);
    } catch (error: unknown) {
      const heapError = error as HeapTrackError;
      const sentryError = new Error(heapError.message);
      sentryError.name = `Failed to track Heap Event ${heapEvent}`;
      captureException(sentryError);
    }
  } else {
    if (retryAttempt > 10) {
      return;
    }
    setTimeout(() => {
      trackHeapEvent(heapEvent, eventData, retryAttempt);
    }, 1000);
  }
};
