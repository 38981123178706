import { useSelector } from 'react-redux';

import { formatCurrency } from 'redux/middlewares/live-server/helpers';
import { selectCurrencyCode } from 'redux/slices/catalog/selectors';
import { isValueNull } from 'utils';

import { ISoldLotOverlayProps } from './types';

import styles from '../lot-card.module.scss';

export function SoldLotOverlay({
  paddle,
  soldAmount,
  acceptedBidder,
  reopenSoldLotPopoverClickHandler,
}: ISoldLotOverlayProps) {
  const currencyCode = useSelector(selectCurrencyCode);

  return (
    <div className={styles['sold-lot-overlay']}>
      <strong>Sold</strong>
      <span className={styles['accepted-bidder']} title={acceptedBidder}>
        {acceptedBidder}
      </span>
      {!isValueNull(paddle) && <span>({`#${paddle}`})</span>}
      <span>{`${currencyCode} ${formatCurrency(soldAmount)}`}</span>
      <div className={styles['reopen-lot-hover']}>
        <button onClick={reopenSoldLotPopoverClickHandler} className={styles['reopen-lot-btn']}>
          Reopen Lot
        </button>
      </div>
    </div>
  );
}
