export type TLotImages = {
  thumbURL?: string;
};

export type TLot = {
  lotID: number;
  lotRef: string;
  lotNumber: string;
  title: string;
  description: string;
  primaryImage: TLotImages;
  estimateLow: number;
  estimateHigh: number;
  orderIndex: number;
  isSold: boolean;
  soldAmount: number;
  currentWinnerType: string;
  acceptedBidder: string;
  paddle: string;
};

export enum SELLER_CONSOLE_HEAP_EVENTS {
  SELLER_CONSOLE_LOAD_SUCCESSFUL = 'Seller Console - Successful',
  SELLER_CONSOLE_LOAD_UNSUCCESSFUL = 'Seller Console - Unsuccessful',
}

export interface HeapTrackError extends Error {
  message: string;
}

declare global {
  interface Window {
    heap: {
      track: (heapEvent: string, eventData?: unknown) => void;
      loaded: boolean;
    };
  }
}
