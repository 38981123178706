import { useSelector } from 'react-redux';

import { formatAcceptedBidder } from 'components/helpers';
import { formatCurrency } from 'redux/middlewares/live-server/helpers';
import { selectCurrentBid } from 'redux/slices/broker/selectors';
import { selectCurrentLot } from 'redux/slices/catalog/selectors';

import styles from './current-bid-high-bidder.module.scss';

export function CurrentBidHighBidder() {
  const currentBid = useSelector(selectCurrentBid);
  const currentLot = useSelector(selectCurrentLot);
  const acceptedBidder = currentLot?.acceptedBidder;
  const paddle = currentLot?.paddle;
  const formattedBidder = formatAcceptedBidder(acceptedBidder, paddle);

  return (
    <>
      <div className={styles['current-bid']}>
        <span>Current Bid:</span>
        <span>{formatCurrency(currentBid)}</span>
      </div>
      <div className={styles['high-bidder']}>
        <span>High Bidder:</span>
        <span title={acceptedBidder}>{formattedBidder}</span>
      </div>
    </>
  );
}
