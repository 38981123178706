import { appendShiftCapsLockToHotKeys } from './helpers';
import { MAX_DIGITS_FOR_BID_AMOUNTS } from './right-panel/constants';

const STATIC_IMAGES_ROOT_DIR = '/images';

export const STATIC_ASSETS = {
  NO_IMAGE: `${STATIC_IMAGES_ROOT_DIR}/no-img.gif`,
};

export const INVALUABLE_HOSTNAME = 'invaluableauctions.com';
export const AUCTIONZIP_HOSTNAME = 'auctionzipauctions.com';

export const BIDDER_NAME_CHAR_LIMIT = 24;

export const POSITIVE_NUMBERS_REGEX = /^[0-9]+$/;

export const STATIC_MESSAGES = {
  ASK_CHANGE_BID_ERROR: 'Ask price must be greater than high bid',
  AUCTION_ENDED: 'Auction has ended',
  AUCTION_NOT_STARTED: 'Auction Not Started',
  AUCTION_PAUSED: 'Auction Paused',
  ENTER_ONLY_NUMBERS: 'Please enter only numbers',
  FAIR_WARNING: 'Fair Warning!',
  NO_BID: 'There are no accepted bids.',
  START_AUCTION_FIRST: 'Please start the auction first!',
  MAX_DIGITS_MSG_FOR_ASK_AND_INCREMENT: `Please enter no more than ${MAX_DIGITS_FOR_BID_AMOUNTS} digits`,
  API_ERROR_500: 'Error: please try again and review troubleshooting procedures.',
  LOT_ALREADY_SOLD_ERROR:
    'This lot has been sold. Please press pass to move on to the next lot and then reopen this lot if needed',
};

export const HOT_KEYS_MAP = {
  CHECK_ALL_THE_AVAILABLE_KEYS: '*',
  ACCEPT_INTERNET_BID: appendShiftCapsLockToHotKeys('i'),
  ACCEPT_FLOOR_BID: `${appendShiftCapsLockToHotKeys('f')}, ${appendShiftCapsLockToHotKeys('space')}`,
  EDIT_INCREMENT_FIELD: appendShiftCapsLockToHotKeys('b'),
  EDIT_ASK_FIELD: `${appendShiftCapsLockToHotKeys('a')}, 0,1,2,3,4,5,6,7,8,9`,
  REVERT_BID: `${appendShiftCapsLockToHotKeys('r')}, ${appendShiftCapsLockToHotKeys('backspace')}`,
  SEND_FIRST_MESSAGE_IN_THE_LIST: appendShiftCapsLockToHotKeys('m'),
  AUTO_ACCEPT_INTERNET_BIDS: 'alt+a',
};

export enum ENVIRONMENTS {
  PRODUCTION = 'production',
  STAGE = 'stage',
}

export enum USER_CONNECTION_STATUS {
  CONNECTED = 'connected',
  RECONNECTING = 'reconnecting',
  OFFLINE = 'offline',
}

export enum HTTP_STATUS_CODES {
  UNAUTHORIZED = 401,
  ALREADY_SOLD = 412,
  SERVER_SIDE_ERROR = 500,
}
