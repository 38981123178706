import { RefObject, useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  selectIsConnectConnectionError,
  selectIsConsoleRegisteredUsingConnect,
  selectIsUserAuthorized,
} from 'redux/slices/auction-info/selectors';
import { selectIsSocketConnected, selectIsSocketConnectionError } from 'redux/slices/broker/selectors';
import { selectIsCatalogConnectionError, selectIsLotsFetched } from 'redux/slices/catalog/selectors';
import { SELLER_CONSOLE_HEAP_EVENTS } from 'types';
import { trackHeapEvent } from 'utils';

/**
   A hook used for tracking the seller console on load heap event. Based on consoleStartTimestamp,
   console time to load is calculated for the heap event.
 */
export const useConsoleOnLoadHeapEvent = (consoleStartTimestampRef: RefObject<number>) => {
  const isUserAuthorized = useSelector(selectIsUserAuthorized);
  const isLotsFetched = useSelector(selectIsLotsFetched);
  const isSocketConnected = useSelector(selectIsSocketConnected);
  const isConsoleConnected = useSelector(selectIsConsoleRegisteredUsingConnect);
  const isConnectError = useSelector(selectIsConnectConnectionError);
  const isCatalogError = useSelector(selectIsCatalogConnectionError);
  const isSocketError = useSelector(selectIsSocketConnectionError);

  const isConnectionError = isConnectError || isCatalogError || isSocketError;
  const isLoading = !isLotsFetched || !isSocketConnected || !isConsoleConnected;

  useEffect(() => {
    if (consoleStartTimestampRef?.current) {
      const { SELLER_CONSOLE_LOAD_UNSUCCESSFUL, SELLER_CONSOLE_LOAD_SUCCESSFUL } = SELLER_CONSOLE_HEAP_EVENTS;
      const timeToLoad = new Date().getTime() - consoleStartTimestampRef.current;

      if (isUserAuthorized && !isLoading && !isConnectionError) {
        trackHeapEvent(SELLER_CONSOLE_LOAD_SUCCESSFUL, { timeToLoad });
      }

      if (!isUserAuthorized || (isLoading && isConnectionError)) {
        trackHeapEvent(SELLER_CONSOLE_LOAD_UNSUCCESSFUL, { timeToLoad });
      }
    }
  }, [isUserAuthorized, isLoading, isConnectionError]);
};
