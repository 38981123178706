import { useEffect, useRef, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';

import { PageLoadingOverlay, Header, LeftPanel, RightPanel } from 'components';
import { AuthorizationFailedModal } from 'components/authorization-failed-modal';
import { Alert } from 'components/common/alert';
import { HOT_KEYS_MAP } from 'components/constants';
import { LastLotTimer } from 'components/last-lot-timer';
import { MultipleConnectionsModal } from 'components/multiple-connections-modal';
import { SalesforceLiveAgent } from 'components/salesforce-live-agent';
import { useSentryIo, useAxeCore, useGTM } from 'hooks/integrations';
import { useConsoleOnLoadHeapEvent } from 'hooks/use-console-on-load-heap-event';
import { mutateLot } from 'redux/middlewares/live-server/actions';
import { fetchAuctionInfo } from 'redux/slices/auction-info';
import {
  selectIsConsoleRegisteredUsingConnect,
  selectIsSellerIdMismatched,
  selectIsUserAuthorized,
} from 'redux/slices/auction-info/selectors';
import { initiateSocketConnection, terminateSocketConnection } from 'redux/slices/broker';
import { selectIsSocketConnected, selectCurrentLotNumber } from 'redux/slices/broker/selectors';
import { fetchCatalog } from 'redux/slices/catalog';
import { selectIsLotsFetched, selectCatalogRef } from 'redux/slices/catalog/selectors';

import styles from './app.module.scss';

function App() {
  const dispatch = useDispatch();

  const catalogRef = useSelector(selectCatalogRef);
  const isLotsFetched = useSelector(selectIsLotsFetched);
  const isSocketConnected = useSelector(selectIsSocketConnected);
  const isConsoleConnected = useSelector(selectIsConsoleRegisteredUsingConnect);
  const isSellerIdMismatched = useSelector(selectIsSellerIdMismatched);
  const currentLotNumber = useSelector(selectCurrentLotNumber);
  const isUserAuthorized = useSelector(selectIsUserAuthorized);
  const [isInitialLotEndpointCalled, setIsInitialLotEndpointCalled] = useState(false);
  const consoleStartTimestampRef = useRef(0);
  const queryParams = new URLSearchParams(window.location.search);
  const houseId = queryParams.get('houseID') || '';
  const userId = queryParams.get('userID') || '';

  const isLoading = !isLotsFetched || !isSocketConnected || !isConsoleConnected;

  useAxeCore();
  useSentryIo();
  useGTM();
  useConsoleOnLoadHeapEvent(consoleStartTimestampRef);

  /**
   * using the hot-keys-hook for all keys to disable the browser's default behavior of all keys which browser allows.
   */
  useHotkeys(HOT_KEYS_MAP.CHECK_ALL_THE_AVAILABLE_KEYS, (event: KeyboardEvent): void => {
    event.preventDefault();
    (document.activeElement as HTMLElement).blur();
  });

  useEffect(() => {
    if (catalogRef) {
      consoleStartTimestampRef.current = new Date().getTime();
      dispatch(initiateSocketConnection());
      dispatch(fetchCatalog());
      dispatch(fetchAuctionInfo({ houseId, userId }));
    }
    return () => {
      dispatch(terminateSocketConnection());
    };
  }, []);

  useEffect(() => {
    if (currentLotNumber && !isInitialLotEndpointCalled) {
      dispatch(mutateLot());
      setIsInitialLotEndpointCalled(true);
    }
  }, [currentLotNumber]);

  return (
    <>
      {!isUserAuthorized && <AuthorizationFailedModal />}
      {isLoading ? (
        <PageLoadingOverlay />
      ) : (
        <div className={styles['main-container']}>
          <Header />
          <LeftPanel />
          <RightPanel />
          <Alert />
          {isSellerIdMismatched && <MultipleConnectionsModal />}
          <LastLotTimer />
        </div>
      )}
      <SalesforceLiveAgent />
    </>
  );
}

export default App;
